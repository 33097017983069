import React from 'react'
import {FaBars} from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem,NavLinks } from './NavBarElements'
const Navbar = () => {
    return(
        <>
            <Nav>
                <NavbarContainer>                    
                    <MobileIcon>
                        <FaBars />                        
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='home'>home</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='about'>about</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='services'>services</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='contact'>contact</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar