import {BrowserRouter as Router} from 'react-router-dom'

import Navbar from './components/NavBar';
import Home from './pages'

function App(){
  return(
    <Router>
      <Navbar />
      <Home />
    </Router>
  )
}

export default App;
