import styled from 'styled-components'

export const HeroContainer = styled.div`
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
`

export const HeroBG = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position:absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroH1 = styled.h1`
    color: #dadbdd;
    font-size: 48px;
    text-align: center;
    -webkit-text-stroke: 1px black;
    @media screen and (max-width: 768px){
        font-size: 30px;
    }

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin-top: 24px;
    color: grey;
    font-size: 20px;
    font-weight: lighter;
    text-align: center;
    max-width: 675px;
    padding-top: 200px;

    @media screen and ( max-width: 768px){
        font-size: 20px;
    }

    @media screen and ( max-width: 480px){
        font-size: 12px;
        padding-top: 25px;
    }
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 250px 0 10px 0;
    padding-right: 0;
`
