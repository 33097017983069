import React from 'react'
import Navbar from  '../components/NavBar'
import HeroSection from '../components/HeroSection'


const Home = () => {
    
    return (
        <>
            {<Navbar />}
            {<HeroSection />}
            
        </>
    )
}

export default Home 