import React from 'react'
import { HeroContainer, HeroContent, HeroH1, HeroP, ImgWrap, Img } from './HeroElements'
import knulkLogo from '../../images/knulkLogo1.png'


const HeroSection = () =>{

    return (
        <HeroContainer id="home">
            <ImgWrap>
                <Img src={knulkLogo} />
            </ImgWrap>
            <HeroContent>
                
            <HeroP>American  made.   Precision  solutions.</HeroP>              
            </HeroContent>
        </HeroContainer>
    )

}

export default HeroSection